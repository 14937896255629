import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../style/index.scss"
import PrenotaOra from "../components/prenotaOra"

const Servizi = ({ data }) => {
  return (
    <Layout page="servizi">
      <Seo
        title="Servizi"
        description="Consulenza psicologica, Terapia di coppia, Valutazione psicodiagnostica, Psicoterapia sensomotoria, EMDR - guarire dal trauma, Psicoterapia e caos, Psicoterapia cognitivo-comportamentale, Percorso breve per la riduzione dell’ansia"
      />
      <article id="servizi">
        <section>
          <h1>Psicoterapia individuale</h1>
          <div className="service-block with-pic">
            <div>
              <p>Cosa sta succedendo?</p>
              <h4>La vita non sta andando nella giusta direzione?</h4>
              <p>
                Se hai un lavoro che richiede il massimo da te, probabilmente sei abituat* a dare sempre tutto quello che hai per raggiungere i tuoi obiettivi. Questo &egrave; molto gratificante ovviamente, ti ha permesso di arrivare l&igrave; dove sei ora, per&ograve; a lungo andare senti sempre di pi&ugrave; quanto sia faticoso, quanto ti costi e quanto avresti bisogno di una pausa… una pausa che tuttavia senti di non poterti permettere visti gli standard che ti sei prefissat* nel tempo.
              </p>
            </div>
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo6.childImageSharp.fluid} loading="eager" />
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>E non riesci pi&ugrave; a stare bene</h4>
              <p>
                In questo modo una o pi&ugrave; parti della tua vita che ti piacevano davvero, anzi di cui andavi fier*, sono diventate insostenibili, pesanti, ti senti quasi di non volerle pi&ugrave; nella tua vita: ormai il gioco non vale pi&ugrave; la candela e il malessere che ne consegue ti impedisce addirittura di essere efficace come sei sempre stat* nel lavoro, nelle relazioni, nella famiglia…
              </p>
            </div>
          </div>

          <div className="service-block with-pic">
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo7.childImageSharp.fluid} loading="eager" />
            </div>
            <div>
              <h4>&Egrave; tempo di riprenderti la tua vita!</h4>
              <p>
                Come gestire tutto ci&ograve;? Come tornare ad essere in grado di ritrovare quella serenit&agrave; che ti potrebbe permettere di esprimere al meglio il tuo potenziale, cos&igrave; da raggiungere gli obiettivi che ti sei prefissat* senza aver paura di rimanere bloccat* di nuovo?
                Cambiare &egrave; possibile, stare meglio &egrave; possibile!
              </p>
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>Una soluzione &egrave; possibile: prova a fare qualcosa di diverso</h4>
              <p>
                Tramite un percorso di psicoterapia insieme a me, potrai capire cosa si &egrave; bloccato in te, cosa c’&egrave; che non ti permette di andare avanti n&eacute; di riconoscerti. Probabilmente il tuo corpo gi&agrave; ti manda dei segnali al riguardo (disturbi intestinali, ansia frequente e intensa, problemi a letto con il tuo partner, difficolt&agrave; a dormire, attacchi di panico…), io posso insegnarti a riconoscere e rispettare questi segnali cosicch&eacute; tu possa rientrare in contatto col tuo corpo e, tramite i suoi segnali, sintonizzarti con i tuoi bisogni emotivi, quelli che fanno davvero la differenza. Attraverso il processo nel quale ti guider&ograve; potrai riacquistare il tuo senso di autostima e la possibilit&agrave; di tornare ad essere efficace nei diversi ambiti della tua vita: sarai davvero te stess* e potrai esserne soddisfatt*!
              </p>
            </div>
          </div>

          <div className="service-block with-pic">
            <div>
              <h4>Questo &egrave; un invito</h4>
              <p>
                Se sei arrivat* fin qua significa che questo non &egrave; un bel periodo per te e che gli effetti di questo problema sulla tua vita sono davvero importanti, di certo non possono essere ignorati oltre; ammettere di aver bisogno di aiuto non &egrave; facile, ma sicuramente non &egrave; una vergogna! Con il mio aiuto puoi raggiungere i tuoi obiettivi nel pi&ugrave; breve tempo possibile e con la garanzia di avere un appoggio professionale da parte di chi ha gi&agrave; lavorato e lavora tuttora con persone che hanno problemi come il tuo.
              </p>
            </div>
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo8.childImageSharp.fluid} loading="eager" />
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>&Egrave; tempo di agire</h4>
              <p>
                Questo &egrave; il momento di fare un altro passo verso la persona che vuoi essere!
              </p>
            </div>
          </div>
          <PrenotaOra />
        </section>

        <section>
          <h1>Lo stress di essere sempre al top</h1>

          <div className="service-block">
            <Img className="normal-size pic" fluid={data.photo9.childImageSharp.fluid} loading="eager" />
          </div>

          <div className="service-block with-pic">
            <div>
              <p>Qual &egrave; il costo di tutto questo?</p>
              <h4>Sei un artista?</h4>
              <p>
                Lavori nel mondo dello spettacolo? Se la risposta &egrave; s&igrave;, probabilmente sai bene cosa vuol dire curare alla perfezione i dettagli in ci&ograve; che fai, sei abituat* a spendere ore e ore del tuo tempo ogni giorno nel prepararti, studiare e far s&igrave; che le tue prestazioni siano sempre al top. Eppure senti che qualcosa non va, che tutta la fatica e l’impegno che metti nel tuo lavoro non siano ripagati dai successi che ottieni, come se non fosse mai abbastanza…
              </p>
            </div>
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo10.childImageSharp.fluid} loading="eager" />
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>Nonostante gli sforzi non basta mai!</h4>
              <p>
                Oggi ti ritrovi ad essere quasi sempre stanc*, a provare molta tristezza e rabbia senza sapere il perch&eacute;, cos&igrave;, di punto in bianco e senza una ragione precisa; questo ti fa arrabbiare ancora di pi&ugrave; e poi ti fa sentire ancora pi&ugrave; triste ed inutile! Nonostante tutto l’impegno e la passione che ci metti ogni giorno, l’unica cosa che ti resta &egrave; un fortissimo senso di fallimento, un malessere che si espande a macchia d’olio in ogni altro ambito della tua vita: sulla famiglia, sulle amicizie, nella relazione con il/la tuo/a partner.
              </p>
            </div>
          </div>

          <div className="service-block with-pic">
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo11.childImageSharp.fluid} loading="eager" />
            </div>
            <div>
              <h4>&Egrave; tempo di riprenderti la tua arte!</h4>
              <p>
                Ma &egrave; davvero necessario pagare un prezzo cos&igrave; alto per portare avanti le tue passioni? Davvero per riuscire nel tuo lavoro devi sentirti cos&igrave; frustrat* ogni giorno? Sul serio bisogna stare male per fare ci&ograve; che si ama? Immagina come sarebbe la tua vita senza questo senso di malessere quotidiano, senza sentire sempre la stanchezza, senza dover subire delle emozioni che si accendono cos&igrave; dal nulla: potresti finalmente tornare a goderti il tuo lavoro, i tuoi successi, le tue giornate, la tua famiglia e la tua arte! 
              </p>
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>Una soluzione &egrave; possibile: prova a dare un senso a ci&ograve; che sta succedendo</h4>
              <p>
                Come si fa a rendere possibile tutto ci&ograve;? Attraverso l’approccio cognitivo-comportamentale capiremo insieme da cosa sia generato il tuo malessere, quando si &egrave; originato e soprattutto come si mantiene facendoti soffrire tutti i giorni; in questo modo potremo individuare i circoli viziosi in cui sei bloccat* e, una volta compreso il loro funzionamento, romperli per permetterti di riprendere in mano la tua vita! Avendo vissuto in prima persona il mondo dello spettacolo e tutto ci&ograve; che ha intorno, dalle gioie alle grandi fatiche, so bene cosa stai passando e potr&ograve; mostrarti come la psicoterapia cognitivo-comportamentale mi ha permesso di superare tante difficolt&agrave;, le stesse che stai passando tu proprio ora.
              </p>
            </div>
          </div>

          <div className="service-block with-pic">
            <div>
              <h4>Questo &egrave; un invito</h4>
              <p>
                La sofferenza psicologica non &egrave; una condanna, cambiare &egrave; possibile e si pu&ograve; stare meglio! L’arte &egrave; una forma di comunicazione estremamente sofisticata, riguarda la persona in tutta se stessa e arriva al pubblico in modo diretto e chiaro, pi&ugrave; forte di qualsiasi altra. Perch&eacute; sia efficace per&ograve; &egrave; importante che l’artista sia serenamente sintonizzato col messaggio che vuole mandare, senza subire quelle distrazioni o interferenze che possono essere generate da un malessere psicologico.
              </p>
            </div>
            <div className="pic-wrapper">
              <Img className="normal-size pic" fluid={data.photo12.childImageSharp.fluid} loading="eager" />
            </div>
          </div>

          <div className="service-block">
            <div>
              <h4>&Egrave; tempo di agire</h4>
              <p>
                Sei a un passo dall'iniziare un percorso che ti permetter&agrave; di riprendere in mano la tua vita e la tua arte, affiancat* da un professionista che sa, prima per esperienza personale e poi professionale, cosa voglia dire dover essere sempre al top, faticare tanto per essere pronti e non sentirsi comunque abbastanza. Cambiare &egrave; possibile!
              </p>
            </div>
          </div>

          <PrenotaOra />
        </section>
      </article>
    </Layout>
  )
}

export default Servizi

export const query = graphql`
  query {
    photo6: file(relativePath: { eq: "anthony-tran-vXymirxr5ac-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo7: file(relativePath: { eq: "duy-pham-Cecb0_8Hx-o-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo8: file(relativePath: { eq: "ivana-cajina-7JWXbeBB4gY-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo9: file(relativePath: { eq: "edrin-spahiu-a9X7rBh-A5Y-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo10: file(relativePath: { eq: "felix-mooneeram-evlkOfkQ5rE-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo11: file(relativePath: { eq: "paul-green-mln2ExJIkfc-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo12: file(relativePath: { eq: "vlah-dumitru-FvmwloIbCeQ-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
